
/* Task Detail */




.task-detail {
  .task-dates {
    li {
      width: 50%;
      float: left;
    }
  }
  .task-tags {
    .bootstrap-tagsinput {
      padding: 0px;
      border: none;
      background: transparent;
    }
  }
  .assign-team {
    a {
      display: inline-block;
      margin: 5px 5px 5px 0px;
    }
  }
  .files-list {
    .file-box {
      display: inline-block;
      vertical-align: middle;
      width: 80px;
      padding: 2px;
      -webkit-border-radius: 3px;
      border-radius: 3px;
      -moz-border-radius: 3px;
      background-clip: padding-box;
      img {
        line-height: 70px;
      }
      p {
        width: 100%;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .add-new-plus {
    height: 32px;
    text-align: center;
    width: 32px;
    display: block;
    line-height: 32px;
    color: $text-muted;
    font-weight: 700;
    background-color: lighten($gray-100, 4%);
    border-radius: 50%;
  }
}



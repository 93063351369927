/* ===========
File Manager
============== */

.file-man-box {
  padding: 20px;
  border: 1px solid lighten($light, 4%);
  position: relative;

  .file-close {
    color: $danger;
    position: absolute;
    line-height: 24px;
    font-size: 24px;
    right: 10px;
    top: 10px;
    visibility: hidden;
  }

  .file-img-box {
    line-height: 120px;
    text-align: center;

    img {
      height: 64px;
    }
  }

  .file-download {
    font-size: 32px;
    color: $text-muted;
    position: absolute;
    right: 10px;

    &:hover {
      color: $dark;
    }
  }
  .file-man-title {
    padding-right: 25px;
  }

  &:hover {
    box-shadow: $box-shadow;

    .file-close {
      visibility: visible;
    }
  }
}


//
// vector-maps.scss
//

.jvectormap-label {
  border: none;
  background: $light;
  color: $white;
  font-family: $font-family-secondary;
  font-size: $font-size-base;
  padding: 5px 8px;
}

.jvectormap-zoomin, 
.jvectormap-zoomout {
  background-color: lighten($light, 6%);
}
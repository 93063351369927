/* =============
   Summernote
============= */
/* =============
   Summernote
============= */

@font-face {
  font-family: "summernote";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/summernote.eot");
  src: url("../fonts/summernote.eot?#iefix") format("embedded-opentype"), url("../fonts/summernote.woff?") format("woff"), 
    url("../fonts/summernote.ttf?") format("truetype")
}

.note-editor{
  &.note-frame {
    border: 1px solid $input-border-color;
    box-shadow: none;
    margin: 0;

    .note-statusbar {
      background-color: lighten($light, 4%);
      border-top: 1px solid $gray-200;
    }

    .note-editable {
        border: none;
        background-color: $light !important;
        color: $gray-400 !important;
    }
  }
}

.note-status-output {
  display: none;
}

.note-editable {
  p {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.note-popover .popover-content .note-color .dropdown-menu,
.card-header.note-toolbar .note-color .dropdown-menu {
    min-width: 344px;
}

.note-popover{
  &.popover{
    border-color: $light;
  }
  .popover-content{
    background: lighten($light, 6%) !important;
  }
}

.note-toolbar {
   z-index: 1;
   background: lighten($light, 4%) !important;
}

.note-btn-group {
  .btn-light {
    background-color: $primary !important;
    color: $white !important;
    border-radius: 4px !important;
    border: none !important;
    margin: 2px;
    box-shadow: none !important;
  }
}

















/* =============
   Task board
============= */

.taskList {
  min-height: 40px;
  margin-bottom: 0;

  li {
    background-color: lighten($light, 4%) !important;
    border: 1px solid lighten($light, 4%);
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 3px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
  a {
    font-size: 13px;
  }
  .checkbox {
    margin-left: 20px;
    margin-top: 5px;
  }
}

.task-placeholder {
  border: 1px dashed rgba($light,0.9) !important;
  background-color: rgba($light,0.2) !important;
  padding: 20px;
}


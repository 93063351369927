/* =============
   Count Down - Coming Soon
============= */


.counter-number {
  font-size: 26px;
  font-family: $font-family-secondary;
  font-weight: $font-weight-medium;
  color: $dark;
  span {
      font-size: 13px;
      font-weight: $font-weight-normal;
      display: block;
      text-transform: uppercase;
      padding-top: 20px;
  }
}

.coming-box {
  float: left;
  width: 25%;
}

.svg-rocket {
  height: 80px;
}

.rocket-clouds__bubble,
.rocket-clouds__cloud,
.rocket-rocket,
.rocket-inner__rocket-and-lines {
  fill: $primary;
}




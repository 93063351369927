//
// Daterange
//

/* Daterange Picker */
.daterangepicker td.active, .daterangepicker td.active:hover {
    background: $primary;
  }
  .daterangepicker .input-mini.active {
    border: 1px solid rgba($dark,0.3);
  }
  .daterangepicker {
    background: lighten($light,4%);
    border-color: lighten($light,8%);


    .calendar-table{
      background-color: $light;
      border: 1px solid  $light;
      .next, .prev{
          span{
              border-color: $gray-500;
          }
      }
  }
  th, td{
      padding: 5px;
      &.available{
          &:hover{
              background-color: $primary;
              background: $primary;
              color: $white;
          }
      }
  }

  td{
      background-color: $light;
      color:  $gray-300;
      &.off, &.off.end-date, &.off.in-range, &.off.start-date{
          background-color: $light;
          color: $gray-300;
          opacity: .5;
      }

      &.in-range{
          background-color: lighten($light, 4%);
          color: $gray-500;
      }
  }

  .ranges {
  margin: 4px;
      li{
        border-radius: 2px;
        color: $dark;
        font-size: 12px;
        background-color: $light;
        border-color: $light;
        margin-bottom: 4px;
          &:hover{
              background-color: lighten($light, 4%);
          }
      }
  }

  td.active, td.active:hover, .ranges li.active {
      background-color: $primary;
      background: $primary;
  }
  .drp-buttons {
      border-top: 1px solid lighten($light, 4%);
      .btn{
          font-weight: $font-weight-medium;
      }
  }

  .drp-calendar.left{
      border-color: lighten($light, 4%) !important;
  }

  select{
      &.ampmselect, &.yearselect, &.monthselect,&.hourselect, &.minuteselect, &.secondselect{
          background-color: lighten($light, 2%);
          border-color: lighten($light, 4%);
          color: $white;
      }
  }





  }
  
  
/* =================
   Ribbons
==================== */

.ribbon-box {
  position: relative;
  .ribbon-dark {
    color: $gray-100 !important;
  }
.ribbon-two-dark{
  span {
    color: $gray-100 !important;
     }
  }
  .ribbon{
    position: relative;
    float: left;
    clear: both;
    padding: 5px 12px 5px 12px;
    margin-left: -30px;
    margin-bottom: 15px;
    font-family: $font-family-secondary;
    box-shadow: 2px 5px 10px rgba($light, 0.15);
    color: $white;
    font-size: 13px;

    &:before{
      content: " ";
      border-style: solid;
      border-width: 10px;
      display: block;
      position: absolute;
      bottom: -10px;
      left: 0;
      margin-bottom: -10px;
      z-index: -1;
    }
  }


  .ribbon + p {
    clear: both;
  }


  @each $color, $value in $theme-colors {
    .ribbon-#{$color} {
        background: ($value);

        &:before {
          border-color: darken(($value),10%) transparent transparent;
        }
    }
  }

  /* Ribbon two */
  .ribbon-two {
    position: absolute;
    left: -5px;
    top: -5px;
    z-index: 1;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: right;


    span {
      font-size: 13px;
      color: $white;
      font-family: $font-family-secondary;
      text-align: center;
      line-height: 20px;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      width: 100px;
      display: block;
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
      position: absolute;
      top: 19px;
      left: -21px;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 100%;
        z-index: -1;
        border-right: 3px solid transparent;
        border-bottom: 3px solid transparent;
      }

      &:after {
        content: "";
        position: absolute;
        right: 0;
        top: 100%;
        z-index: -1;
        border-left: 3px solid transparent;
        border-bottom: 3px solid transparent;
      }
    }
    
  }

  @each $color, $value in $theme-colors {
    .ribbon-two-#{$color} {

      span {
        background: ($value);
  
        &:before {
          border-left: 3px solid darken(($value),15%);
          border-top: 3px solid darken(($value),15%);
        }
  
        &:after {
          border-right: 3px solid darken(($value),15%);
          border-top: 3px solid darken(($value),15%);
        }
      }
    }
  }
}
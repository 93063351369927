//
// right-sidebar.scss
//

.right-bar {
    background-color: $light;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
    display: block;
    position: fixed;
    transition: all 200ms ease-out;
    width: $rightbar-width;
    z-index: 9999;
    float: right !important;
    right: -($rightbar-width + 10px);
    top: 0;
    bottom: 0;

    .rightbar-title {
        background-color: $primary;
        padding: 27px 25px;
        color: $white;
    }
    .right-bar-toggle {
        background-color: lighten($dark, 7%);
        height: 24px;
        width: 24px;
        line-height: 24px;
        color: $gray-100;
        text-align: center;
        border-radius: 50%;
        margin-top: -4px;

        &:hover {
            background-color: lighten($dark, 10%);
        }
    }
    .user-box {
        padding: 25px;
        text-align: center;
        .user-img {
            position: relative;
            height: 64px;
            width: 64px;
            margin: 0 auto 15px auto;
            .user-edit {
                position: absolute;
                right: -5px;
                bottom: 0px;
                height: 24px;
                width: 24px;
                background-color: $white;
                line-height: 24px;
                border-radius: 50%;
                box-shadow: $box-shadow-lg;
            }
        }
        h5 {
            margin-bottom: 2px;
            a {
                color: $dark;
            }
        }
    }
}

// Rightbar overlay
.rightbar-overlay {
    background-color: rgba($dark, 0.55);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: none;
    z-index: 9998;
    transition: all .2s ease-out;
}

.right-bar-enabled {
    .right-bar {
        right: 0;
    }
    .rightbar-overlay {
        display: block;
    }
}

@include media-breakpoint-down(sm) {
    .right-bar {
        overflow: auto;
        .slimscroll-menu {
            height: auto !important;
        }
    }
}

.activity-widget{
    .activity-list{
        position: relative;
        border-left: 2px dashed $gray-400;
        padding-left: 24px;
        padding-bottom: 2px;
        &::after{
            content: "";
            position: absolute;
            left: -7px;
            top: 6px;
            width: 12px;
            height: 12px;
            background-color: $white;
            border: 2px solid $primary;
            border-radius: 50%;
        }
    }
}

// Inbox-widget(Used Profile)
.inbox-widget {
    .inbox-item {
        overflow: hidden;
        padding: 0.625rem 0;
        position: relative;

        .inbox-item-img {
            display: block;
            float: left;
            margin-right: 15px;
            margin-top: 4px;

            img {
                width: 40px;
            }
        }

        .inbox-item-author {
            display: block;
            margin-bottom: 0px;
            font-weight: $font-weight-medium;
            a{
                color: $gray-700;
            }
        }

        .inbox-item-text {
            color: $gray-600;
            display: block;
            margin: 0;
            overflow: hidden;
        }

        .inbox-item-date {
            color: $gray-600;
            font-size: 0.6875rem;
            position: absolute;
            right: 5px;
            top: 10px;
        }
    }
}
